<template>
  <div class="px-3.5 -mt-10">
    <div rounded="5" class="shadow-[0_2px_8px_0px_rgba(122,122,122,0.25)]">
      <div class="tab-list space-x-2">
        <div
          v-for="tab in [
            { label: 'Earn', id: 'earn', icon: 'my-icon:earn' },
            { label: 'Burn', id: 'burn', icon: 'my-icon:burn' },
          ]"
          :key="tab.id"
          class="tab-item"
          :class="coninsActiveTab === tab.id ? 'tab-selected' : 'not-selected'" @click="coninsActiveTab = tab.id as E_CONINS_ACTIVE_TAB"
        >
          <div flex="~ items-center" class="space-x-2">
            <NuxtIcon
              :name="tab.icon"
              class="align-middle text-xl"
              :style="{
                color: coninsActiveTab === tab.id ? 'var(--tc-color-bg-index)' : '#000000',
              }"
            />
            <span text="black lg" font="600">{{ tab.label }}</span>
          </div>
          <div class="tab-corner"></div>
        </div>
      </div>
      <div rounded="5" p="4" bg="white" flex="~ items-center" class="space-x-5">
        <div flex="1" h="full" bg="#F4F4F4" rounded="2.5">
          <BaseAZPopover class="w-full" :show-close-icon="false" align="start" :align-offset="-16" :side-offset="20">
            <template #reference>
              <input p="y-3.5 x-5" w="full" :placeholder="placeholderConfig.placeholderText" :disabled="placeholderConfig.disabled" />
            </template>
            <!-- List Container -->
            <div class="divide-y divide-gray-200" w="200">
              <!-- Loop through reward programs -->
              <NuxtLink
                v-for="program in rewardPrograms"
                :key="program.name"
                :to="program.href"
                :external="true"
                class="block cursor-pointer px-4 py-1.5 font-medium hover:rounded-lg"
                :class="[
                  program.href ? 'cursor-pointer text-black hover:bg-#EFEFEF' : 'cursor-not-allowed text-[#B3B3B3]',
                ]"
                @click="handleSearch"
              >
                {{ `${program.name}${program.href ? '' : t('key.home.coins.not.launched')}` }}
              </NuxtLink>
            </div>
          </BaseAZPopover>
        </div>
        <NuxtLink
          v-bind="placeholderConfig.linkTo"
          p="y-2 x-6"
          class="block rounded-2.5 bg-black text-center text-xl text-white font-800"
          :style="{
            backgroundColor: 'var(--tc-color-bg-index)',
          }"
          @click="handleSearch"
        >
          <NuxtIcon name="my-icon:search" class="align-middle" text="3xl" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const handleSearch = defineEmits(['search'])

const { t } = useI18n()
const { query } = useRoute()
const { listRoutePath } = usePartnerConfig()
const { coninsActiveTab } = useUseBurnCoins()

const placeholderConfig = computed(() => {
  return coninsActiveTab.value === E_CONINS_ACTIVE_TAB.EARN
    ? { placeholderText: t('key.home.coins.select.program'), disabled: false, linkTo: { to: { path: listRoutePath.value, query } } }
    : { placeholderText: t('key.how.to.earn.learn.more'), disabled: true, linkTo: { to: { path: 'https://corporate.azgotrip.com/burn-program' }, target: '_blank', external: true,
      } }
})

const rewardPrograms = ref([
  {
    name: 'Ryde',
    href: 'https://triatemotterry.com/35a6ff12-f3b7-4b37-a707-bcbab497e7d0?member_id=124234234',
  },
  {
    name: 'TADA',
  },
  {
    name: 'CDG',
  },
  {
    name: 'Atome',
    // href: 'https://triatemotterry.com/79ba670b-cbf0-4157-898f-169939eee614?member_id={member_id}',
  },
  {
    name: 'AXS',
  },
  {
    name: 'Simplygo',
  },
  {
    name: 'Wink+',
  },
  {
    name: 'HomeTeam NS',
  },
  {
    name: 'MoneyBack',
  },
  {
    name: 'DFS',
  },
  {
    name: 'Yuu Rewards',
  },
])
</script>

<style lang="scss" scoped>
  $tab-height: 62px;
  $active-color: #ffffff;
  $not-active-color: #EFEFEF;
  $default-color: #e2e8f8;
  $primary-color: blue;

  .tab-page {
    border: 1px solid red;
  }

  .tab-list {
    display: flex;
    position: relative;
    z-index: 2;
    border-radius: 20px 20px 0 0;
    // background-color: $default-color;
    overflow: hidden; // 重点

    .tab-item {
      flex: 1;
      height: $tab-height;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: $primary-color;
      font-weight: 600;
      position: relative;
    }

    .tab-icon {
      width: 17px;
      height: 17px;
      margin-right: 4px;
      margin-top: 1px;
    }

    .tab-selected {
      opacity: 1;
      background: #ffffff;
      border-radius: 20px 20px 0 0;
      box-shadow: 40px 50px 0 $active-color, -40px 50px 0 0 $active-color; // 重点
      position: relative;
      z-index: 1;

      .tab-corner {
        position: absolute;
        right: -35px;
        background: #EFEFEF;
        bottom: 0;
        width: 20px;
        height: 20px;
        border-bottom-left-radius: 15px;
        transform: skewX(20deg);
      }
    }

    .not-selected {
      background-color: #EFEFEF;
      border-radius: 20px 20px 0 0;

      .tab-corner {
        position: absolute;
        right: 8px;
        background: #EFEFEF;
        bottom: 0;
        width: 20px;
        height: 20px;
        border-bottom-right-radius: 15px;
        background-color: $not-active-color;
        transform: skewX(-20deg);
        z-index: 10;
      }
    }

    .tab-selected::before {
      content: '';
      position: absolute;
      left: -8px;
      bottom: 0;
      width: 20px;
      height: $tab-height;
      border-top-left-radius: 15px;
      background-color: $active-color;
      transform: skewX(-20deg); // 重点
    }

    .tab-selected::after {
      content: '';
      position: absolute;
      right:-8px;
      bottom: 0;
      width: 20px;
      height: $tab-height;
      border-top-right-radius: 15px;
      background-color: $active-color;
      transform: skewX(20deg); // 重点

    }

    .not-selected::before {
      content: '';
      position: absolute;
      left: -8px;
      bottom: 0;
      width: 20px;
      height: $tab-height;
      border-top-left-radius: 15px;
      background-color: $not-active-color;
      transform: skewX(-20deg); // 重点
    }

    .not-selected::after {
      content: '';
      position: absolute;
      right: -8px;
      bottom: 0;
      width: 20px;
      height: $tab-height;
      border-top-right-radius: 15px;
      background-color: $not-active-color;
      transform: skewX(20deg); // 重点
    }
  }
</style>
